import { useContext, useEffect, useState } from "react";
import { checkoutWithSplit } from "../../../../services/sales-point";
import { ToastContext } from "../../../../hooks/context";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import { cleanObject } from "../../../../helpers";
import { useParams } from "react-router-dom";
import { usePayViaStoreWalletMutation } from "../../../../hooks/api/mutations/useOrder";
import { useStoreCurrency } from "../../../../hooks/useStoreCurrency";
import { useCloseOtpAuthorizationPrompt } from "../../../../hooks/useCloseOtpAuthorizationPrompt";

const SplitPaymentLogic = ({
  closePaymentSession,
  orderDetails,
  selectBank,
}) => {
  const { store_currency } = useStoreCurrency();
  const { currency_code, business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { closeOtpAuthorizationPrompt } = useCloseOtpAuthorizationPrompt();

  const { store_id } = useParams();
  const storeDetails = storeList?.find((item) => `${item?.id}` === store_id);

  const payViaStoreWallet = usePayViaStoreWalletMutation();

  const [cashAmount, setCashAmount] = useState("");
  const [transferAmount, setTransferAmount] = useState("");
  const [cardAmount, setCardAmount] = useState("");
  const [creditAmount, setCreditAmount] = useState("");
  const [storeWalletAmount, setStoreWalletAmount] = useState("");
  const [prAmount, setPrAmount] = useState("");
  const [customerDetails, setCustomerDetails] = useState({
    customerPhoneCode: business_phone_code,
  });
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const [repaymentDate, setRepaymentDate] = useState(null);
  const [isOtpWidget, setIsOtpWidget] = useState(null);
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (orderDetails?.customer)
      setCustomerDetails({
        customerPhoneCode: orderDetails?.customer?.phone_code,
        customerName: orderDetails?.customer?.full_name,
        customerPhoneNumber: orderDetails?.customer?.phone,
        customerEmail: orderDetails?.customer?.email,
      });
  }, []);

  const payViaSplit = () => {
    if (storeWalletAmount && Number(storeWalletAmount) > 0) {
      payViaStoreWallet.mutate(
        {
          order_id: orderDetails?.id,
          customer_name: customerDetails?.customerName,
          customer_email: customerDetails?.customerEmail,
          customer_phone_code: customerDetails?.customerPhoneCode,
          customer_phone_number: customerDetails?.customerPhoneNumber,
          is_split: true,
          amount: Number(storeWalletAmount),
        },
        {
          onSuccess: (res) => {
            const isOtpRequired = res?.data?.data?.otp_required;
            triggerToast(
              isOtpRequired
                ? "OTP has been sent to customer's phone number and email"
                : `A sum of ${store_currency} ${Number(storeWalletAmount)} has been debited from customer: (${customerDetails?.customerName}) which is use to process this order`,
              "success",
            );
            if (isOtpRequired) return setIsOtpWidget(res?.data?.data);

            if (
              Number(cardAmount) +
                Number(transferAmount) +
                Number(creditAmount) +
                Number(cashAmount) +
                Number(storeWalletAmount) +
                Number(prAmount) -
                Number(orderDetails?.total_amount) !==
              0
            )
              return triggerToast(
                `Please ensure that your estimated balance is ${currency_code} 0`,
                "warning",
              );

            setIsConfirmingPayment(true);
            const data = cleanObject({
              order_id: orderDetails?.id,
              cash_amount: Number(cashAmount),
              transfer_amount: Number(transferAmount),
              card_amount: Number(cardAmount),
              credit_amount: Number(creditAmount),
              pr_amount: Number(prAmount),
              repayment_date: repaymentDate,
              ledger_account_id: selectBank,
            });
            if (Number(creditAmount) > 0 || Number(storeWalletAmount) > 0) {
              data.customer_name = customerDetails?.customerName;
              data.customer_phone_number = customerDetails?.customerPhoneNumber;
              data.customer_phone_code =
                customerDetails?.customerPhoneCode || business_phone_code;
              data.customer_email = customerDetails?.customerEmail;
            }

            if (Number(storeWalletAmount) > 0) {
              data.store_wallet_session_id = res?.data?.data?.session_id;
            }

            checkoutWithSplit(data)
              .then(() => {
                triggerToast("Payment successful", "success");
                queryClient.invalidateQueries([
                  "orderDetails",
                  orderDetails?.id,
                ]);
                closePaymentSession();
                closeOtpAuthorizationPrompt();
              })
              .catch(() => {})
              .finally(() => setIsConfirmingPayment(false));
          },
        },
      );
    } else {
      if (
        Number(cardAmount) +
          Number(transferAmount) +
          Number(creditAmount) +
          Number(cashAmount) +
          Number(prAmount) -
          Number(orderDetails?.total_amount) !==
        0
      )
        return triggerToast(
          `Please ensure that your estimated balance is ${currency_code} 0`,
          "warning",
        );

      setIsConfirmingPayment(true);
      const data = cleanObject({
        order_id: orderDetails?.id,
        cash_amount: Number(cashAmount),
        transfer_amount: Number(transferAmount),
        card_amount: Number(cardAmount),
        credit_amount: Number(creditAmount),
        pr_amount: Number(prAmount),
        repayment_date: repaymentDate,
        ledger_account_id: selectBank,
      });
      if (creditAmount > 0) {
        data.customer_name = customerDetails?.customerName;
        data.customer_phone_number = customerDetails?.customerPhoneNumber;
        data.customer_phone_code =
          customerDetails?.customerPhoneCode || business_phone_code;
        data.customer_email = customerDetails?.customerEmail;
      }

      checkoutWithSplit(data)
        .then(() => {
          triggerToast("Payment successful", "success");
          queryClient.invalidateQueries(["orderDetails", orderDetails?.id]);
          closePaymentSession();
          closeOtpAuthorizationPrompt();
        })
        .catch(() => {})
        .finally(() => setIsConfirmingPayment(false));
    }
  };

  return {
    isConfirmingPayment,
    cashAmount,
    transferAmount,
    cardAmount,
    creditAmount,
    storeWalletAmount,
    prAmount,
    repaymentDate,
    customerDetails,
    storeDetails,
    isOtpWidget,
    setIsOtpWidget,
    setCustomerDetails,
    setTransferAmount,
    setCashAmount,
    setCardAmount,
    setCreditAmount,
    setStoreWalletAmount,
    setPrAmount,
    setRepaymentDate,
    setIsConfirmingPayment,
    payViaSplit,
    closeOtpAuthorizationPrompt,
  };
};

export default SplitPaymentLogic;
