import { useContext } from "react";
import { ToastContext } from "../../../../hooks/context";
import { useState } from "react";
import { usePayViaStoreWalletMutation } from "../../../../hooks/api/mutations/useOrder";
import { useStoreCurrency } from "../../../../hooks/useStoreCurrency";

const useStoreWalletPayment = ({ orderDetails, closePaymentSession }) => {
  const { store_currency } = useStoreCurrency();
  const triggerToast = useContext(ToastContext);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [isOtpWidget, setIsOtpWidget] = useState(null);

  const payViaStoreWallet = usePayViaStoreWalletMutation();
  const handlePayViaStoreWallet = () => {
    payViaStoreWallet.mutate(
      {
        order_id: orderDetails?.id,
        customer_name: customerDetails?.customerName,
        customer_email: customerDetails?.customerEmail,
        customer_phone_code: customerDetails?.customerPhoneCode,
        customer_phone_number: customerDetails?.customerPhoneNumber,
        amount: 0,
      },
      {
        onSuccess: (res) => {
          const isOtpRequired = res?.data?.data?.otp_required;
          triggerToast(
            isOtpRequired
              ? "OTP has been sent to customer's phone number and email"
              : `A sum of ${store_currency} ${orderDetails?.total_amount} has been debited from customer: (${customerDetails?.customerName}) which is use to process this order`,
            "success",
          );
          if (isOtpRequired) return setIsOtpWidget(res?.data?.data);

          closePaymentSession();
        },
      },
    );
  };

  return {
    customerDetails,
    isConfirmingPayment: payViaStoreWallet?.isLoading,
    isOtpWidget,
    store_currency,
    setIsOtpWidget,
    handlePayViaStoreWallet,
    setCustomerDetails,
  };
};
export default useStoreWalletPayment;
