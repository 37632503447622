import { useMemo } from "react";
import {
  capitalizeLetter,
  defaultDateDisplay,
  formatCurrency,
  handleRenderStatusIndicator,
} from "../helpers";
import "../assets/styles/base/globalPage.scss";
import globalStyles from "../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../components/primaryComponents";
import {
  useAssignRiderToOrderMutation,
  useUpdateOrderStatusMutation,
} from "./api/mutations/useOrder";
import { useGetRiders } from "./api/queries/useRiders";
import secondaryComponents from "../components/secondaryComponents";

const useStoreFrontDisplay = ({
  setSelectedOrder = () => {},
  handleRefetchOrders = () => {},
}) => {
  const { data: riders, isLoading: isFetchingRiders } = useGetRiders();
  const { updateStatus } = useUpdateOrderStatusMutation({
    onSuccess: () => {
      handleRefetchOrders();
    },
  });
  const { assignRider } = useAssignRiderToOrderMutation({
    onSuccess: () => {
      handleRefetchOrders();
    },
  });

  const orderColor = {
    ORDER_READY: "#15112d",
    COMPLETED: "#19AE57",
    SHIPPED: "#F7AD3C",
    RETURNED: "#ff9292",
    DELIVERED: "#7647ee",
  };

  const columns = useMemo(
    () => [
      {
        Header: "Order Number",
        accessor: "id",
        Cell: ({ cell: { value }, row }) => (
          <span>
            <span
              onClick={() => {
                setSelectedOrder(value);
              }}
              style={{
                color: "#7647EE",
                cursor: "pointer",
              }}
            >
              {value}
            </span>
            <span className="d-block">
              {defaultDateDisplay(row?.original?.created_at)}
            </span>
          </span>
        ),
      },
      {
        Header: "Customer Name",
        accessor: "customer",
        Cell: ({ cell: { value } }) =>
          value === null ? "N/A" : value?.full_name,
      },
      {
        Header: "Amount",
        accessor: "total_amount",
        Cell: ({ cell }) => (
          <span>
            {formatCurrency({
              value: cell.value,
              currencyCode: cell?.row?.original?.store?.currency_code,
            })}
          </span>
        ),
      },
      {
        Header: "Payment Status",
        accessor: "is_paid",
        Cell: ({ cell: { value } }) => (
          <span
            className={globalStyles.statusBlock}
            style={{
              ...handleRenderStatusIndicator(
                value ? "COMPLETED" : "IN_PROGRESS",
              ),
            }}
          >
            {value ? "Successful" : "Pending"}
          </span>
        ),
      },
      {
        Header: "Delivery Type",
        accessor: "order_type",
        Cell: ({ cell: { value } }) => (
          <span>{value === "DINE_IN" ? "PICK UP" : "DELIVERY"}</span>
        ),
      },
      {
        Header: "Actions",
        accessor: "status",
        Cell: ({ cell: { value }, row }) => (
          <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <secondaryComponents.ToolTipV2 tipText="Update Order Status">
              <primaryComponents.FancySelectField
                placeholder={"Update status"}
                options={[
                  {
                    name: "Order Ready",
                    id: "ORDER_READY",
                    bgColor: "#15112d",
                  },
                  { name: "Shipped", id: "SHIPPED", bgColor: "#F7AD3C" },
                  { name: "Delivered", id: "DELIVERED", bgColor: "#7647ee" },
                  { name: "Completed", id: "COMPLETED", bgColor: "#19AE57" },
                  { name: "Returned", id: "RETURNED", bgColor: "#ff9292" },
                ]}
                nameKey={"name"}
                idKey={"id"}
                bgKey={"bgColor"}
                style={{ height: "40px" }}
                selectedItem={{
                  label: capitalizeLetter(value?.replace("_", " ")),
                  bgColor: orderColor[value],
                }}
                onChange={(item) => {
                  updateStatus(row?.original?.id, item?.value);
                }}
              />
            </secondaryComponents.ToolTipV2>
            {!isFetchingRiders ? (
              <secondaryComponents.ToolTipV2 tipText="Assign Rider">
                <div style={{ width: "140px" }} className={"selectPropMid"}>
                  <primaryComponents.SelectField
                    options={riders?.data}
                    value={
                      riders?.data?.find(
                        (rider) =>
                          rider?.id === row?.original?.dispatch_rider?.id,
                      )?.name || "Assign Rider"
                    }
                    placeholder={"Assign Rider"}
                    floatError={false}
                    nameKey={"name"}
                    idKey={"id"}
                    onChange={(value) => {
                      assignRider(row?.original?.id, value);
                    }}
                    isDisabled={!row?.original?.delivery_address}
                  />
                </div>
              </secondaryComponents.ToolTipV2>
            ) : null}
          </span>
        ),
      },
    ],
    [isFetchingRiders],
  );

  return {
    columns,
  };
};

export default useStoreFrontDisplay;
