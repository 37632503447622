import "./AddMultipleProducts.scss";
import primaryComponents from "../../../../components/primaryComponents";
import Icon from "../../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AddMultipleProductsLogic from "./AddMultipleProductsLogic";
import { platformHandler } from "../../../../hooks/context";
import { useContext } from "react";
import { getPlatform } from "../../../../helpers";

const AddMultipleProducts = () => {
  const navigate = useNavigate();
  const { isUploadingFile, inputRef, handleProductUpload } =
    AddMultipleProductsLogic();
  const { platform } = useContext(platformHandler);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      className="add-multiple-products-page"
    >
      <h3 className="font-weight-semibold">Add Multiple Products</h3>
      <p>Upload your bulk product file here:</p>
      <input
        style={{ display: "none" }}
        type="file"
        name="products"
        id="products"
        onChange={handleProductUpload}
        ref={inputRef}
        accept=".csv"
      />
      <label htmlFor="products">
        <primaryComponents.Button
          isLoading={isUploadingFile}
          classNames={"btn btn--primary mt-4"}
          loadingText="Uploading..."
          onClick={() => inputRef.current.click()}
        >
          <img className="mr-2" src={Icon.upload} alt="" />
          Upload bulk product CSV
        </primaryComponents.Button>
      </label>
      <p className="mt-2 dark--text font-weight-semibold">
        Download bulk creation template
        <a
          href={
            process.env.REACT_APP_PRODUCT_BATCH_UPLOAD_DOWNLOAD_URL ||
            "https://enlumi-research-app.s3.eu-west-2.amazonaws.com/static/New+Product+Template.csv"
          }
          className="ml-2 purple--text"
        >
          here
        </a>
      </p>
      <div className="mt-4">
        <h5>Step-by-Step Guide for Uploading Products to Your Store</h5>
        <p className="dark--text mb-2">
          1.{" "}
          <span className="font-weight-semibold">
            Download the sample spreadsheet:{" "}
          </span>
          Start by downloading the sample spreadsheet provided here. This
          spreadsheet will serve as a template for organizing your product data.{" "}
          <a
            href={
              process.env.REACT_APP_PRODUCT_BATCH_UPLOAD_DOWNLOAD_URL ||
              "https://enlumi-research-app.s3.eu-west-2.amazonaws.com/static/New+Product+Template.csv"
            }
            className="purple--text"
          >
            Download here
          </a>
        </p>
        <p className="dark--text mb-2">
          2.{" "}
          <span className="font-weight-semibold">Obtain your store ID: </span>
          In order to successfully upload your products to your store, you will
          need your unique store ID. To find out how to obtain your store.{" "}
          <a
            href={getPlatform(platform) + "/inventory/stores"}
            target="_blank"
            className="purple--text"
            rel="noreferrer"
          >
            Click here
          </a>
        </p>
        <p className="dark--text mb-2">
          3.{" "}
          <span className="font-weight-semibold">
            Prepare your product unit list:{" "}
          </span>
          Ensure you have a complete list of product units ready for upload. For
          a reference guide on common product units, please consult the list
          provided{" "}
          <a
            href="https://enlumidatainc.zohodesk.com/portal/en/kb/articles/list-of-products-metrics-and-their-symbols-on-lumi-business"
            target="_blank"
            className="purple--text"
            rel="noreferrer"
          >
            here.
          </a>{" "}
        </p>
        <p className="dark--text">
          4.{" "}
          <span className="font-weight-semibold">
            Watch the full video tutorial:{" "}
          </span>
          For a detailed walkthrough of the bulk upload process, we recommend
          watching our comprehensive video tutorial. This video will guide you
          through each step of the process, from organizing your data in the
          spreadsheet to successfully uploading your products to your store.{" "}
          <a
            href="https://enlumidatainc.zohodesk.com/portal/en/kb/articles/how-to-bulk-upload-products-on-lumi-business"
            target="_blank"
            className="purple--text"
            rel="noreferrer"
          >
            Click here
          </a>
        </p>
      </div>
      <primaryComponents.Button
        onClick={() => navigate(-1)}
        classNames={"btn btn--outline back__btn"}
      >
        Back
      </primaryComponents.Button>
    </motion.div>
  );
};

export default AddMultipleProducts;
