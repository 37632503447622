import { formatCurrency } from "../helpers";

export const useSubscriptionFeatures = ({ tierCurrency }) => {
  const isNigeria = tierCurrency === "NGN" ? true : false;

  return {
    subscriptionFeatures: [
      {
        name: "GENERAL",
        GENERAL: [
          {
            name: "Users",
            tiers: {
              free: { value: "1" },
              growth: { value: "2" },
              essential: { value: "5" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Store",
            tiers: {
              free: { value: "1" },
              growth: { value: "1" },
              essential: { value: "3" },
              scale: { value: "7" },
            },
          },
          {
            name: "Products",
            tiers: {
              free: { value: "Unlimited" },
              growth: { value: "Unlimited" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Variants",
            tiers: {
              free: { value: "Unlimited" },
              growth: { value: "Unlimited" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Sales Orders",
            tiers: {
              free: { value: "5/month" },
              growth: { value: "500/month" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Suppliers",
            tiers: {
              free: { value: "Unlimited" },
              growth: { value: "Unlimited" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "End of Day Report",
            tiers: {
              free: { value: "Unlimited" },
              growth: { value: "Unlimited" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Inventory Export",
            tiers: {
              free: { value: "Unlimited" },
              growth: { value: "Unlimited" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Reorder & Expiry Notifications",
            tiers: {
              free: { value: "Unlimited" },
              growth: { value: "Unlimited" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Stock Removal",
            tiers: {
              free: { value: "Unlimited" },
              growth: { value: "Unlimited" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Split Payments",
            tiers: {
              free: { value: "-" },
              growth: { value: "Unlimited" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Basic Reporting",
            tiers: {
              free: { value: "-" },
              growth: { value: "Unlimited" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Reconciliation",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Credit Tracking",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Stock Transfers",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Custom Pricing",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Hold Orders on Checkout",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Returns",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
        ],
      },
      {
        name: "ADVANCED SELLING",
        "ADVANCED SELLING": [
          {
            name: "Bill Generation",
            tiers: {
              free: { value: "-" },
              growth: { value: "Unlimited" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Invoices",
            tiers: {
              free: { value: "", checked: true },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Waybills",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Price Variants",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Store and Product Discounts",
            tiers: {
              free: { value: "-" },
              growth: { value: "Unlimited" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Credit Note",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Recipe Management",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "", checked: true },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Advanced Reporting",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "-" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Item Level Tracking",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Barcode Scanning",
            tiers: {
              free: { value: "Unlimited" },
              growth: { value: "-" },
              essential: { value: "", checked: true },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Credit Limits",
            tiers: {
              free: { value: "-" },
              growth: { value: "Unlimited" },
              essential: { value: "", checked: true },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Rebate",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "-" },
              scale: { value: "Unlimited" },
            },
          },
        ],
      },
      {
        name: "E-COMMERCE",
        "E-COMMERCE": [
          {
            name: "Storefront/Menu",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Online Payments",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "International Payments",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Shipping Zones",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "", checked: isNigeria },
              scale: { value: "", checked: true },
            },
          },
        ],
      },
      {
        name: "RESTAURANTS",
        RESTAURANTS: [
          {
            name: "Tables",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Kitchen Display",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "-" },
              scale: { value: "Unlimited" },
            },
          },
        ],
      },
      {
        name: "CUSTOMER",
        CUSTOMER: [
          {
            name: "Customer Creation",
            tiers: {
              free: {
                value: "3",
              },
              growth: {
                value: "30",
              },
              essential: {
                value: "Unlimited",
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
          {
            name: "Customer Management Module",
            tiers: {
              free: { value: "-" },
              growth: { value: "", checked: true },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Customer Store Wallet",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
        ],
      },
      {
        name: "LOYALTY",
        LOYALTY: [
          {
            name: "Loyalty Programs",
            tiers: {
              free: {
                value: "-",
              },
              growth: {
                value: "-",
              },
              essential: {
                value: "Unlimited",
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
          {
            name: "Gift Cards",
            tiers: {
              free: { value: "-" },
              growth: { value: "-" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
        ],
      },
      {
        name: "PAYMENTS",
        PAYMENTS: [
          {
            name: "International Payments",
            tiers: {
              free: {
                value: "-",
              },
              growth: {
                value: "-",
              },
              essential: {
                value: "Unlimited",
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
        ],
      },
      {
        name: "CRM",
        CRM: [
          {
            name: "Send Emails",
            tiers: {
              free: {
                value: "1",
              },
              growth: {
                value: "3",
              },
              essential: {
                value: "5",
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
          {
            name: "Send SMS",
            tiers: {
              free: {
                value: "1",
              },
              growth: {
                value: "3",
              },
              essential: {
                value: "5",
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
        ],
      },
      {
        name: "EXPENSE MANAGEMENT",
        "EXPENSE MANAGEMENT": [
          {
            name: "Expenses",
            tiers: {
              free: {
                value: "",
                checked: true,
              },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
        ],
      },
      {
        name: "PHARMACY",
        PHARMACY: [
          {
            name: "Prescription",
            tiers: {
              free: {
                value: "Unlimited",
              },
              growth: {
                value: "Unlimited",
              },
              essential: {
                value: "Unlimited",
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
        ],
      },
      {
        name: "REPORTS",
        REPORTS: [
          {
            name: "PNL Report",
            tiers: {
              free: {
                value: "",
                checked: true,
              },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
        ],
      },
      {
        name: "ADD-ONS",
        "ADD-ONS": [
          {
            name: "Number of Locations",
            tiers: {
              free: {
                value: "1",
              },
              growth: {
                value: "1",
              },
              essential: {
                value: "1",
              },
              scale: {
                value: "1",
              },
            },
          },
        ],
      },
      {
        name: "OFFLINE MODE",
        "OFFLINE MODE": [
          {
            name: "Price",
            tiers: {
              free: {
                value: `${formatCurrency({
                  value: 7000,
                  currencyCode: "NGN",
                  decimal: 0,
                })}`,
              },
              growth: {
                value: `${formatCurrency({
                  value: 7000,
                  currencyCode: "NGN",
                  decimal: 0,
                })}`,
              },
              essential: {
                value: `${formatCurrency({
                  value: 7000,
                  currencyCode: "NGN",
                  decimal: 0,
                })}`,
              },
              scale: {
                value: `${formatCurrency({
                  value: 7000,
                  currencyCode: "NGN",
                  decimal: 0,
                })}`,
              },
            },
          },
        ],
        //dontCheckCountry: false,
      },
      {
        name: "ADDITIONAL USERS",
        "ADDITIONAL USERS": [
          {
            name: "Price",
            tiers: {
              free: {
                value: `${formatCurrency({
                  value: 0,
                  currencyCode: "NGN",
                  decimal: 0,
                })}`,
              },
              growth: {
                value: `${formatCurrency({
                  value: 5000,
                  currencyCode: "NGN",
                  decimal: 0,
                })}`,
              },
              essential: {
                value: `${formatCurrency({
                  value: 3600,
                  currencyCode: "NGN",
                  decimal: 0,
                })}`,
              },
              scale: {
                value: `${formatCurrency({
                  value: 2000,
                  currencyCode: "NGN",
                  decimal: 0,
                })}`,
              },
            },
          },
        ],
        //dontCheckCountry: false,
      },
    ],
  };
};
